<template>
  <div
    class="vad-container"
    v-lazy:background-image="{src: require('./images/value_advantage_bg.png')}"
  >
    <div
      class="page-outter"
      style="width: 63%"
    >
      <div
        align="center"
        style="margin-top: 60px"
      >
        <img
          class="vad-title"
          v-lazy="require('./images/value_advantage.png')"
        />
      </div>
      <div class="vad-ccd">
        <div
          class="vad-ccd-d"
          v-for="(item,index) in tds"
          :key="index"
          align="center"
        >
          <img
            class="m1"
            v-lazy="item.icon"
          />
          <span class="s1">{{item.label}}</span>
          <div
            class="s2"
            v-html="item.cont"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "valueAdvantage",
  data() {
    return {
      tds: [
        {
          icon: require("./images/icon_marketing.png"),
          label: "丰富的营销模块",
          cont:
            "可与实体店打通会员体系<br/>线上线下多重服务 <br/> 实现深度绑定 <br/> 多样化营销模块 <br/> 适合多种营销场景 <br/> 提升用户粘性",
        },
        {
          icon: require("./images/icon_big_data.png"),
          label: "大数据分析支持",
          cont:
            "后台提供一站式数据分析服务<br/> 深度剖析商品、用户、<br/> 营销等多维度数据， <br/>提升运营效率、增强用户体验",
        },
        {
          icon: require("./images/icon_popularizes.png"),
          label: "全渠道推广",
          cont:
            "九医云针对电商平台<br/> 开展全渠道营销推广<br/> 并在九医云用户端平台<br/> 设置商城入口<br/>为入驻商户带来流量支持",
        },
        {
          icon: require("./images/icon_guarantees.png"),
          label: "合规保障",
          cont:
            "平台符合国家相关法律法规要求<br/>全力整合行业资源<br/>帮助药店提升专业服务能力<br/>合法销售处方药",
        },
        {
          icon: require("./images/icon_ecological_empowerment.png"),
          label: "生态赋能",
          cont:
            "九医云致力于为传统药店<br/>提供大数据解决方案<br/>打通药厂资源<br/>实现供应链优化管理<br/>降低药店采购成本，提升效率",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.vad-container {
  width: 100%;
  height: 500px;
  padding-top: 36px;
}
.vad-title {
  width: 12.3rem;
}
.vad-ccd {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}
.vad-ccd-d {
  .m1 {
    display: block;
    width: 2rem;
  }
  .s1 {
    display: block;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    margin-top: 20px;
  }
  .s2 {
    font-size: 13px;
    color: #fff;
    margin-top: 10px;
    line-height: 1.8;
  }
}
</style>